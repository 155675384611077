<template lang="html">

  <section class="breacrumbs">
    <div class="container" >
      <router-link :to="{name:'home'}">Inicio</router-link>
      <router-link  v-if="this.$route.query.slug" :to="{name:'offersPage'}">Vacantes</router-link>
      <span v-if="dataTitle">{{dataTitle}}</span>
    </div>


  </section>

</template>

<script lang="js">

  export default {
    name: 'breacrumbs',
    props: ['dataParent', 'dataTitle', 'dataParentLink'],
    mounted() {

    },
    data() {
      return {

      }
    },
    methods: {

    },
    computed: {

    }
  }


</script>

<style scoped lang="scss">
  .breacrumbs {}
</style>