<template lang="html">
  <section class="site-map">
    <bannerHead dataTitle="Política de Cookies"></bannerHead>
    <breacrumbs dataTitle="Política de Cookies"></breacrumbs>
    <div class="list-map cookies">
      <div class="container">
        <h1>¿Qué son las cookies?</h1>
        <p>
          Este sitio web utiliza cookies y/o tecnologías similares que almacenan
          y recuperan información cuando navegas. En general, estas tecnologías
          pueden servir para finalidades muy diversas, como, por ejemplo,
          reconocerte como usuario, obtener información sobre tus hábitos de
          navegación, o personalizar la forma en que se muestra el contenido.
          Los usos concretos que hacemos de estas tecnologías se describen a
          continuación.
        </p>
        <h1>¿Qué tipo de cookies existen?</h1>
        <p>A manera enunciativa y no taxativa, las siguientes:</p>
        <ul>
          <li class="number">
            Tipos de cookies según la entidad que la gestione:
            <ul>
              <li class="letter">
                <strong>Cookies propias:</strong> son aquellas que se envían al
                equipo terminal del usuario desde un equipo o dominio gestionado
                por el propio editor y desde el que se presta el servicio
                solicitado por el usuario.
              </li>
              <li class="letter">
                <strong>Cookies de tercero:</strong> son aquellas que se envían
                al equipo terminal del usuario desde un equipo o dominio que no
                es gestionado por el editor, sino por otra entidad que trata los
                datos obtenidos través de las cookies.
              </li>
            </ul>
          </li>
          <li class="number">
            Tipos de cookies según su finalidad:
            <ul>
              <li class="letter">
                <strong>Cookies técnicas:</strong> son aquellas que permiten al
                usuario la navegación a través de una página web, plataforma o
                aplicación y la utilización de las diferentes opciones o
                servicios que en ella existan, incluyendo aquellas que el editor
                utiliza para permitir la gestión y operativa de la página web y
                habilitar sus funciones y servicios, como, por ejemplo,
                controlar el tráfico y la comunicación de datos, identificar la
                sesión, acceder a partes de acceso restringido, recordar los
                elementos que integran un pedido, realizar el proceso de compra
                de un pedido, gestionar el pago, controlar el fraude vinculado a
                la seguridad del servicio, realizar la solicitud de inscripción
                o participación en un evento, contar visitas a efectos de la
                facturación de licencias del software con el que funciona el
                servicio (sitio web, plataforma o aplicación), utilizar
                elementos de seguridad durante la navegación, almacenar
                contenidos para la difusión de vídeos o sonido, habilitar
                contenidos dinámicos (por ejemplo, animación de carga de un
                texto o imagen) o compartir contenidos a través de redes
                sociales. También pertenecen a esta categoría, por su naturaleza
                técnica, aquellas cookies que permiten la gestión, de la forma
                más eficaz posible, de los espacios publicitarios que, como un
                elemento más de diseño o “maquetación” del servicio ofrecido al
                usuario, el editor haya incluido en una página web, aplicación o
                plataforma en base a criterios como el contenido editado, sin
                que se recopile información de los usuarios con fines distintos,
                como puede ser personalizar ese contenido publicitario u otros
                contenidos.
              </li>
              <li class="letter">
                <strong>Cookies de preferencias o personalización:</strong> son
                aquellas que permiten recordar información para que el usuario
                acceda al servicio con determinadas características que pueden
                diferenciar su experiencia de la de otros usuarios, como, por
                ejemplo, el idioma, el número de resultados a mostrar cuando el
                usuario realiza una búsqueda, el aspecto o contenido del
                servicio en función del tipo de navegador a través del cual el
                usuario accede al servicio o de la región desde la que accede al
                servicio, etc.
              </li>
              <li class="letter">
                <strong>Cookies de análisis o medición:</strong> son aquellas
                que permiten al responsable de las mismas el seguimiento y
                análisis del comportamiento de los usuarios de los sitios web a
                los que están vinculadas, incluida la cuantificación de los
                impactos de los anuncios. La información recogida mediante este
                tipo de cookies se utiliza en la medición de la actividad de los
                sitios web, aplicación o plataforma, con el fin de introducir
                mejoras en función del análisis de los datos de uso que hacen
                los usuarios del servicio.
              </li>
              <li class="letter">
                <strong>Cookies de publicidad comportamental:</strong> son
                aquellas que almacenan información del comportamiento de los
                usuarios obtenida a través de la observación continuada de sus
                hábitos de navegación, lo que permite desarrollar un perfil
                específico para mostrar publicidad en función del mismo.
              </li>
            </ul>
          </li>
          <li class="number">
            Tipos de cookies según el plazo de tiempo que permanecen activadas:
            <ul>
              <li class="letter">
                <strong> Cookies de sesión:</strong> son aquellas diseñadas para
                recabar y almacenar datos mientras el usuario accede a una
                página web. Se suelen emplear para almacenar información que
                solo interesa conservar para la prestación del servicio
                solicitado por el usuario en una sola ocasión (por ejemplo, una
                lista de productos adquiridos) y desaparecen al terminar la
                sesión.
              </li>
              <li class="letter">
                <strong>Cookies Persistentes:</strong> son aquellas en las que
                los datos siguen almacenados en el terminal y pueden ser
                accedidos y tratados durante un periodo definido por el
                responsable de la cookie, y que puede ir de unos minutos a
                varios años.
              </li>
            </ul>
          </li>
        </ul>
        <h2>Licitud del tratamiento</h2>
        <p>
          La base legal del tratamiento es su consentimiento (libre, específico,
          informado e inequívoco). Podrá revocar su consentimiento, siga las
          instrucciones del siguiente apartado.
        </p>
        <h2>
          Forma de aceptar, denegar, revocar el consentimiento o eliminar las
          cookies
        </h2>
        <p>
          Puede permitir o bloquear las cookies accediendo al configurador de
          las cookies previamente señalado.
        </p>
        <p>
          En el caso de que nuestro sistema de gestión o configuración de las
          cookies no permitiese evitar la utilización de las cookies de terceros
          una vez aceptadas por el usuario, puede usted permitir o bloquear las
          cookies, así como borrar sus datos de navegación (incluidas las
          cookies) desde el navegador que usted utiliza o desde las opciones de
          configuración del tercero accediendo a través del link facilitado
          previamente. Consulte las opciones e instrucciones que ofrece su
          navegador para ello.
        </p>
        <ul>
          <li class="dot">
            Firefox:
            <a
              href="https://support.mozilla.org/es/kb/cookies-informacion-que-los-sitios-web-guardan-en-#w_configurar-las-cookies"
              >https://support.mozilla.org/es/kb/cookies-informacion-que-los-sitios-web-guardan-en-#w_configurar-las-cookies</a
            >
          </li>
          <li class="dot">
            Chrome:
            <a href="https://support.google.com/chrome/answer/95647?hl=es "
              >https://support.google.com/chrome/answer/95647?hl=es
            </a>
          </li>
          <li class="dot">
            Explorer:
            <a
              href="https://support.microsoft.com/es-es/topic/eliminar-y-administrar-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
              >https://support.microsoft.com/es-es/topic/eliminar-y-administrar-cookies-168dab11-0753-043d-7c16-ede5947fc64d
            </a>
          </li>
          <li class="dot">
            Safari:
            <a href="https://support.apple.com/es-es/HT201265"
              >https://support.apple.com/es-es/HT201265
            </a>
          </li>
          <li class="dot">
            Otros navegadores: consulte la documentación del navegador que tenga
            instalado.
          </li>
        </ul>
        <h2>Elaboración de perfiles automatizada con efectos jurídicos</h2>
        <p>
          Las cookies instaladas si bien realizan análisis de perfiles de forma
          automatizada, no produce efectos jurídicos.
        </p>
        <h2>Otra información exigida por el art. 13 del RGPD</h2>
        <p>
          Formando parte del deber de información exigido por el art. 13 del
          RGPD, os remitimos a la Política de privacidad (<a
            href="/politica-de-privacidad"
            >https://ufv-personas.dev.doubledot.es/politica-de-privacidad</a
          >) para informarle de:
        </p>
        <ul>
          <li class="letter">
            la identidad y los datos de contacto del responsable y, en su caso,
            de su representante;
          </li>
          <li class="letter">
            los datos de contacto del delegado de protección de datos, en su
            caso;
          </li>
          <li class="letter">
            la existencia del derecho a solicitar al responsable del tratamiento
            el acceso a los datos personales relativos al interesado, y su
            rectificación o supresión, o la limitación de su tratamiento, o a
            oponerse al tratamiento, así como el derecho a la portabilidad de
            los datos;
          </li>
          <li class="letter">
            el derecho a presentar una reclamación ante una autoridad de
            control;
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script lang="js">
import { contentStore } from '@/stores/contents'
import bannerHead from '@/components/banner-head.vue'
import breacrumbs from '@/components/breacrumbs.vue'
export default {
    name: 'cookies',
    props: [],
    components: {
        bannerHead,
        breacrumbs
    },
    setup() {
        const store = contentStore();

        return {
            store
        }
    },

}
</script>

<style scoped lang="scss">
.site-map {
}
</style>
